<template lang="">
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="card dveb-white-card" style="height:100%">
                <Lines />
            </div>
        </div>
        <div class="col-md-6 col-12">
            <div class="card dveb-white-card h-full">
                <Donut />
            </div>
        </div>

    </div>
</template>
<script>

export default {
 name: 'charts',
 components: {
    Donut: () => import('@/components/dashboard/charts/donut'),
    Lines: () => import('@/components/dashboard/charts/lines'),
  },
}

</script>
<style lang="">
    
</style>